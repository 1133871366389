
import { Watch, Mixins, Component, PropSync, Prop } from 'vue-property-decorator'
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";

import ETable from "@/components/ETable.vue";

import { getDeviceBindRecord } from "@/api/device";

@Component({
    components: { ETable }
})
export default class DeviceBindRecord extends Mixins(loading, tablePagination) {
    @PropSync("visible") dialogVisible!: boolean;
    @Prop({ default: 0 }) readonly deviceId !: number;

    tableData = [];
    columns = [
        { label: "关联门店", prop: "storeName" },
        { label: "操作人", prop: "bindingBy" },
        { label: "操作时间", prop: "createTime" },
    ]


    @Watch("dialogVisible")
    getDeviceBindRecord() {
        if (!this.dialogVisible) return;
        this.tableData = [];
        this.showLoading();
        getDeviceBindRecord({ deviceId: this.deviceId }).then((res) => {
            this.tableData = res.data;
        }).finally(() => {
            this.hideLoading();
        })
    }

    dialogClose() {
        this.dialogVisible = false;
    }
}

